import * as React from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/layout"
import '../styles/global.css'

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const IndexPage = () => {
  return (
    <main className="bg-white" style={pageStyles}>
      <Layout>
        <div className="w-full p-20 flex">
          <h1 className="text-left text-sm md:text-5xl lg:text-5xl font-semibold flex-auto w-1/2">
            <span className="text-yellow-400 mr-2">Humildad</span>
            <span className="text-blue-600 mr-2">Pasión</span>
            <span className="text-green-600 mr-2">Compromiso</span> <br />
            <span className="text-purple-600 mr-2">Perseverancia</span>
            <span className="text-orange-600">Humanismo</span>
          </h1>
        </div>

        <div className="w-full h-auto bg-white`">
          <img className="w-full" src="/images/about3.png" />
        </div>

        <div className="w-full p-10">
          <div className="w-full h-auto flex items-center">
            <div className="text-black text-center sm:text-left">
              <h1 className="text-4xl font-semibold mb-4">¿Qué es "El Amor Te Elige A Ti"?</h1>
              <p style={{ fontFamily: "Raleway", fontWeight: 400 }} className="text-lg">
                Mi nombre es <strong>Araceli Montiel Hernández</strong>,fundadora y 
                representante legal de la institución El Amor Te Elige a Ti, IAP.
                <br /><br />
                Institución integrada por un grupo multidisciplinario que diseña programas específicos para potencializar habilidades académicas
                a niños con discapacidad intelectual, Síndrome de Down, Autismo Leve, TDA-H, y niños integrados en colegios 
                regulares que enfrentan problemas de aprendizaje. Y facilitándoles el proceso, enseñanza aprendizaje, 
                con objetivos concretos que les permitan el logro de sus competencias educativas. 
                
              </p>
            </div>
          </div>
          <br /><br />

          <div className="w-full h-auto flex items-center">
            <div className="text-black text-center sm:text-left">
              <h1 className="text-4xl font-semibold mb-4">¿Cómo nace la organización? </h1>
              <p style={{ fontFamily: "Raleway", fontWeight: 400 }} className="text-lg">
                Soy madre de dos hijos maravillosos de los cuales me siento orgullosa, y quiero compartir cómo
                una madre puede recibir noticias, que pensamos no recibir y menos superar. El 7 de agosto de 2006,
                nació mi segundo hijo, un niño muy deseado y esperado; tenía tantas ganas de conocerlo y ponerlo entre
                mis brazos, durante el embarazo todo fue bien según los médicos.
                <br /><br />
                Todo se agravó a la hora del nacimiento, no me permitieron verlo y se lo 
                llevaron, comencé a llorar, les grité ¡¡¡Quiero ver a mi bebé!!! los médicos 
                en la sala solo murmuraban, minutos después entró el pediatra quien lo 
                levantó y dijo “tiene síndrome de Down“. En ese momento cerré los ojos 
                y pensé: “todo es un sueño”, empecé a llorar, le pregunté: “¿qué es eso
                Doctor?, no entiendo qué está pasando” le grité. “Usted está mintiendo,
                diga que no es verdad!! dígamelo por favor! diga que mi hijo está bien”,
                gritaba una y otra vez.
                <br /><br />
                No podía creer lo que estaba pasando, lloré toda la noche, le gritaba a la enfermera que eso no era cierto,
                ¡¡dígame que no era cierto!! ¡¡lo que me había dicho el médico era una mentira!! Lloraba tanto que se acercó una
                enfermera me dijo "tranquila todo estará bien", tu hijo está bien.
                <br /><br />
                La enfermera me acercó a mi hijo después de horas de no poderlo ver, me abrazó 
                y lloró conmigo, en ese momento le susurré a mi hijo “te voy a cuidar con todo mi corazón”.
                <br /><br />
                Así pasaron los días, yo seguía mirando al bebé, en ocasiones quería dormir,
                despertar, y que alguien me dijera que todo era un sueño, lloré por varios días,
                mirando a mi hijo creí que estaba enloqueciendo, ya que recibir a un niño con discapacidad no fue fácil, 
                en el sentido que fueron momentos confusos y desesperantes donde me preguntaba una y otra vez 
                ¿Por qué me pasó esto a mí? ¿Qué sucedió? ¿Qué es el síndrome de Down?
                <br /><br />
                Al paso de los días me cuestionaba una y otra vez, pero al mismo tiempo empecé a documentarme en libros para
                entender que la discapacidad no es una enfermedad sino una condición de vida y que la verdadera discapacidad
                es el estigma social.
                <br /><br />
                En los libros encontré que el Síndrome de Down es; una alteración genética que se produce
                por la presencia de un cromosoma extra. Las células del cuerpo humano tienen 46 cromosomas distribuidos en 23
                pares Las personas con Síndrome de Down tienen 3 cromosomas en el par 21, en lugar de los dos que existen
                habitualmente; por ello, este síndrome se conoce como trisomía 21.
                <br /><br />
                Lo anterior me ayudó a comprender mayormente qué era lo que estaba pasando y entender una explicación 
                científica, sin saber que con el paso del tiempo esa sería una de las primeras de muchas investigaciones y
                retos a los que me enfrentaría, ya que posteriormente empecé a capacitarme en instituciones, tomando
                capacitación, cursos y talleres sobre el tema para poder ayudar a mi hijo.
                <br /><br />
                Actualmente 2022 me encuentro en la universidad fortaleciendo mi experiencia.
                <p>Mi hijo Braulio ha sido mi motor y mi mejor maestro de vida.</p>
                <br /><br />
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 py-10">
          <div className="w-full">
            <h1 className="text-left md:text-left lg:text-left text-3xl lg:text-4xl font-semibold mb-6 pl-4">Actividades que Ofrecemos:</h1>

              <div className="m-2  bg-purple-700 flex items-center p-2 rounded-md">
            <h1 className="text-left md:text-left lg:text-left text-3xl md:text-3xl lg:text-3xl font-semibold">
              <span className="text-white mr-2">Vida Diaría</span><br />
            </h1>
            </div>

            <div className="m-2  bg-green-600 flex items-center p-2 rounded-md">
            <h1 className="text-left md:text-left lg:text-left text-3xl md:text-3xl lg:text-3xl font-semibold">
              <span className="text-white mr-2">Autonomía</span><br />
            </h1>
            </div>
            
            <div className="m-2  bg-blue-600 flex items-center p-2 rounded-md">
            <h1 className="text-left md:text-left lg:text-left text-3xl md:text-3xl lg:text-3xl font-semibold">
              <span className="text-white mr-2">Vida social e inclusión</span><br />
            </h1>
            </div>

            <div className="m-2  bg-orange-600 flex items-center p-2 rounded-md">
            <h1 className="text-left md:text-left lg:text-left text-3xl md:text-3xl lg:text-3xl font-semibold">
              <span className="text-white mr-2">Terapias de aprendizaje</span><br />
            </h1>
            </div>

            <div className="m-2  bg-red-600 flex items-center p-2 rounded-md">
            <h1 className="text-left md:text-left lg:text-left text-3xl md:text-3xl lg:text-3xl font-semibold">
              <span className="text-white mr-2">Motricidad Fina y Gruesa</span><br />
            </h1>
            </div>


            <div className="m-2  bg-lime-600 flex items-center p-2 rounded-md">
            <h1 className="text-left md:text-left lg:text-left text-3xl md:text-3xl lg:text-3xl font-semibold">
              <span className="text-white mr-2">Desarrollo de Actividades Prelaborales</span><br />
            </h1>
            </div>

            <h1 className="text-left md:text-left lg:text-left text-3xl lg:text-4xl font-semibold mt-12 mb-6 pl-4">Terapias Individuales:</h1>

            <div className="m-2  bg-yellow-600 flex items-center p-2 rounded-md">
            <h1 className="text-left md:text-left lg:text-left text-3xl md:text-3xl lg:text-3xl font-semibold">
              <span className="text-white mr-2">Aprendizaje</span><br />
            </h1>
            </div>

            <div className="m-2  bg-rose-600 flex items-center p-2 rounded-md">
            <h1 className="text-left md:text-left lg:text-left text-3xl md:text-3xl lg:text-3xl font-semibold">
              <span className="text-white mr-2">Terapias Psicológicas </span><br />
            </h1>
            </div>
          </div>  

          <div className="w-full">
            <img className="w-full" src="/images/a6.jpeg" />
          </div>
        </div>
        

        <div className="w-full mx-auto bg-blue-600 h-auto flex items-center mb-10 py-10">
          <div className="sm:ml-20 text-gray-50 text-center sm:text-left">
            <h1 className="text-5xl font-bold mb-4">Finalidad</h1>
            <p className="text-lg inline-block sm:block"> 
              <br /><br />
              <ul className="list-none md:list-disc">

                <li>
                  Mejorar la calidad de vida de nuestra población y familias, nuestro proyecto beneficia a
                  todos los que participan directa e indirectamente: padres de familia, profesionales,
                  terapeutas, educadores, pero principalmente a los niños de escasos recursos.
                </li>
                <br /><br />
                <li>
                  Esta organización civil se sensibiliza en solucionar problemáticas sociales y ofrecer ayuda especialmente a
                  niños con discapacidad intelectual, ya que el municipio de solidaridad existe obstáculos de discriminación y 
                  pobreza en algunos grupos sociales.
               </li>
                <br /><br />
                <li>
                  Existen situaciones difíciles que hay que resolver de manera perseverante y reflexionar y analizar lo que
                  nos compete hacer como personas, buscar el bienestar de otras madres y padres que padecen o padecieron 
                  alguna situación emocional al recibir la noticia de tener a su hijo con discapacidad.
                </li>
                <br /><br />
                <li>
                  Generar en ellos oportunidades de inclusión social y laboral promover los derechos y el bienestar de las
                  personas con discapacidades en todos los ámbitos de la sociedad y el desarrollo de sus habilidades intelectuales,
                  así como concientizar sobre su situación en todos los aspectos de la vida, social, económica y cultural.
                </li>
              
              <br /><br />

                <li>
                  Quizá no podamos solucionar todas las problemáticas sociales que existen en nuestro municipio y en nuestro
                  país, pero queremos contribuir en el bienestar de nuestros jóvenes con discapacidad.
               </li>
              <br /><br />
                <li>
                  Atender a niños (as) integrados en la institución con terapias individualizadas en un
                  horario de 8:00 A.M a 13:00 P.M.  principalmente que padezcan alguna discapacidad
                  descritos en nuestro objetivo
                </li>
              </ul>
              
            </p>
          </div>
        </div>

       
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-10">
          <div className="flex-1 h-auto">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.5693930955963!2d-87.06379338535005!3d20.64640148620674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4e5da27c8365a1%3A0x4b91427df0942195!2sEl%20Amor%20Te%20Elige%20A%20Ti%20IAP!5e0!3m2!1ses!2smx!4v1669508574862!5m2!1ses!2smx"
              width="100%"
              height="450">
            </iframe>
          </div>



          <div className=" max-w-xl rounded overflow shadow-lg lg:mt-10 container mx-auto bg-gray-100 h-100 flex items-center mb-10 p-2">
          <div className="sm:ml-10  text-left sm:text-left">
            <h1 className="text-5xl font-bold mb-4 text-black">¿Dónde nos ubicamos?</h1>
            <p className="text-lg inline-block sm:block text-black">
            Estamos en Playa del Carmen Quintana Roo, con el único fin de mejorar y
            dignificar la calidad de vida de nuestros niños con discapacidad intelectual.
            </p><br />

          <p className="mt-4 text-black">
            Av. 20 Nte 2020, Luis Donaldo Colosio, 77728 Playa del Carmen, Q.R.
          </p>
          </div>
        </div>
        </div>

      </Layout>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>El Amor Te Elige A Ti</title>
